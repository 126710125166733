"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "kiwi-autocomplete kiwi-theme-bg"
  }, _vm._l(_vm.filteredAndLimitedItems, function (item) {
    return _c('div', {
      key: item.type + item.text,
      class: {
        'kiwi-autocomplete-item': true,
        'kiwi-autocomplete-item--selected': item.idx === _vm.selected_idx
      },
      on: {
        "mousedown": function mousedown($event) {
          $event.preventDefault();
        },
        "click": function click($event) {
          _vm.selected_idx = item.idx;
          _vm.selectCurrentItem();
        }
      }
    }, [item.type === 'user' ? [_c('span', {
      staticClass: "kiwi-autocomplete-item-value"
    }, [_vm._v(_vm._s(item.text))]), _c('span', {
      staticClass: "u-link kiwi-autocomplete-item-action",
      on: {
        "click": function click($event) {
          $event.stopPropagation();
          return _vm.openQuery(item.text);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.$t('send_message')) + " ")])] : item.type === 'channel' ? [_c('span', {
      staticClass: "kiwi-autocomplete-item-value"
    }, [_vm._v(_vm._s(item.text))]), _c('span', {
      staticClass: "kiwi-autocomplete-item-count"
    }, [_c('i', {
      staticClass: "fa fa-user",
      attrs: {
        "aria-hidden": "true"
      }
    }), _vm._v(" " + _vm._s(item.count) + " ")])] : item.type === 'command' ? [_c('span', {
      staticClass: "kiwi-autocomplete-item-value"
    }, [_vm._v(_vm._s(item.text))]), _c('span', {
      staticClass: "u-link kiwi-autocomplete-item-description"
    }, [_vm._v(" " + _vm._s(item.description) + " ")])] : [_c('span', {
      staticClass: "kiwi-autocomplete-item-value"
    }, [_vm._v(_vm._s(item.text))])]], 2);
  }), 0);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;