"use strict";
'kiwi public';

// eslint-plugin-vue's max-len rule reads the entire file, including the CSS. so we can't use this
// here as some of the rules cannot be broken up any smaller
/* eslint-disable max-len */
var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _TextFormatting = require("@/helpers/TextFormatting");
var _MessageInfo = _interopRequireDefault(require("./MessageInfo"));
var _MessageListAvatar = _interopRequireDefault(require("./MessageListAvatar"));
var _AwayStatusIndicator = _interopRequireDefault(require("./AwayStatusIndicator"));
var _MediaViewer = _interopRequireDefault(require("./MediaViewer"));
var methods = {
  props: {},
  showRealName: function showRealName() {
    var props = this.props;

    // Showing realname is not enabled
    if (!props.ml.buffer.setting('show_realnames')) {
      return false;
    }

    // Server does not support extended-join so realname would be inconsistent
    var client = props.ml.buffer.getNetwork().ircClient;
    if (!client.network.cap.isEnabled('extended-join')) {
      return false;
    }

    // We dont have a user or users realname
    if (!props.message.user || !props.message.user.realname) {
      return false;
    }

    // No point showing the realname if it's the same as the nick
    if (props.message.user.nick.toLowerCase() === props.message.user.realname.toLowerCase()) {
      return false;
    }

    // If the realname contains a URL it's most likely a clients website
    if (_TextFormatting.urlRegex.test(props.message.user.realname)) {
      return false;
    }
    return true;
  },
  getNetwork: function getNetwork() {
    var props = this.props;
    return props.ml.buffer.getNetwork();
  },
  isRepeat: function isRepeat() {
    var props = this.props;
    var ml = props.ml;
    var idx = props.idx;
    var message = props.message;
    var prevMessage = ml.filteredMessages[idx - 1];
    return !!prevMessage && prevMessage.nick === message.nick && message.time - prevMessage.time < 60000 && prevMessage.type !== 'traffic' && message.type !== 'traffic' && message.type === prevMessage.type && message.day_num === prevMessage.day_num;
  },
  isHoveringOverMessage: function isHoveringOverMessage(message) {
    var props = this.props;
    return message.nick && message.nick.toLowerCase() === props.ml.hover_nick.toLowerCase();
  },
  isMessage: function isMessage(message) {
    var types = ['privmsg', 'action', 'notice', 'message'];
    return types.indexOf(message.type) > -1;
  },
  displayAvatar: function displayAvatar(message) {
    var props = this.props;
    // if there is no user attached hide the avatar
    if (!message.user) {
      return false;
    }

    // if its not a message hide the avatar
    if (!this.isMessage(message)) {
      return false;
    }

    // dont show avatars in server or special buffers
    if (props.ml.buffer.isServer() || props.ml.buffer.isSpecial()) {
      return false;
    }

    // dont show avatar if its a repeat of the same user
    if (this.isRepeat()) {
      return false;
    }
    return true;
  },
  userMode: function userMode(user) {
    var props = this.props;
    return props.ml.buffer.userMode(user);
  },
  userModePrefix: function userModePrefix(user) {
    var props = this.props;
    return props.ml.buffer.userModePrefix(user);
  }
};
var _default = {
  inject: {
    components: {
      default: {
        MessageAvatar: _MessageListAvatar.default,
        MessageInfo: _MessageInfo.default,
        AwayStatusIndicator: _AwayStatusIndicator.default,
        MediaViewer: _MediaViewer.default
      }
    }
  },
  props: {
    ml: Object,
    message: Object,
    idx: Number,
    m: {
      default: function m() {
        // vue uses this function to generate the prop. `this`==null Return our own function
        return function n() {
          // Give our methods some props context before its function is called.
          // This is only safe because the function on the methods object is called on
          // the same js tick
          methods.props = this;
          return methods;
        };
      }
    }
  }
};
exports.default = _default;
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["components"]) window._kiwi_exports["components"] = {};
window._kiwi_exports["components"]["MessageListMessageModern"]
window._kiwi_exports.components.MessageListMessageModern = exports.default ? exports.default : exports;
